import { Link } from 'react-router-dom';
import login_facebook from "../assets/img/btn_login_facebook.png"
import login_twitter from "../assets/img/btn_login_twitter.png"
import login_google from "../assets/img/btn_login_google.png"
import login_linkedin from "../assets/img/btn_login_linkedin.png"
import { apiUrl } from "../utils/utils";
const api = apiUrl();

function Footer() {

  return (
    <footer>
      <p style={{ fontFamily: 'Roboto, sans-serif' }}>Log in with social media</p>
      <div className="icons">
        <a href={`${api}/users/facebook`}>
          <img src={login_facebook} alt="" />
        </a>
        {/* <a href={`/`}>
          <img src={login_twitter} alt="" />
        </a> */}
        <a href={`${api}/users/google`}>
          <img src={login_google} alt="" />
        </a>
        {/* <img src={login_google} onClick={() => googleLogin()} alt="" /> */}
        <a href={`${api}/users/linkedin`}>
          <img src={login_linkedin} alt="" />
        </a>
      </div>
      <div className="buttonarea">
        <Link to="/login" className="btn btn-lg login" >Log In</Link>
        <Link to="/register" className="btn btn-lg register">
          <span onClick={() => window.localStorage.clear()}>Sign Up</span>
        </Link>
      </div>

      <p style={{ fontSize: 14 }}>Copyright © Dance4Healing 2024</p>
      <p style={{ fontSize: 14 }}>Best viewed in <a href="https://www.google.com/chrome/browser/desktop/">Chrome web browser.</a>
      </p>
    </footer>)
}

export default Footer;
