import React, { useState } from "react";
import { randomRoomName } from "../utils/utils";

const WelcomeMenu = ({ username, handleGoToRoom }) => {
  const [hover, setHover] = useState(false);
  const [roomName, setRoomName] = useState("");

  const toggleHover = () => {
    setHover(!hover);
  };

  const goToRoom = (e) => {
    handleGoToRoom(roomName || randomRoomName());
  };

  const handleChange = (e) => {
    setRoomName(e.target.value.toLowerCase());
  };

  return (
    <div className="buttonarea">
      {/* <div className="gap" /> */}
      <div
        onTouchStart={toggleHover}
        className={hover ? "flip-container hover" : "flip-container"}
      >
        <div className="flipper">
          <div className="front">
            <h2>Dance By Yourself</h2>
            <img
              id="fimg1"
              className="flipper-image"
              src={require("../assets/img/graphic_single_dance.png")}
              alt=""
            />
          </div>
          <div className="back">
            <h2 className="flipper-image">Dance By Yourself</h2>
            <p>
              Dance like no one is watching. Enjoy and build healthy habits in a
              fun, easy way.
            </p>
            <form id="form1" className="singleRoom" onSubmit={goToRoom}>
              <br />
            </form>
            <button type="submit" form="form1" value="Submit" className="btnGo">
              Go!
            </button>
          </div>
        </div>
      </div>

      {/* <div className="gap" /> */}
      <div
        onTouchStart={toggleHover}
        className={hover ? "flip-container hover" : "flip-container"}
      >
        <div className="flipper">
          <div className="front">
            <h2>Dance with Your Buddy</h2>
            <img
              id="fimg2"
              src={require("../assets/img/graphic_buddy_dance.png")}
              alt=""
            />
          </div>
          <div className="back">
            <h2>Dance with Your Buddy</h2>
            <p>
              Create a fun group by choosing a cool name (i.e. your favorite cartoon), then start
              rocking the world! &#128512;
            </p>
            <form id="form1" onSubmit={goToRoom}>
              <input
                type="text"
                name="roomName"
                onChange={handleChange}
                value={roomName}
                placeholder="Type a Fun Group Name"
                className="room"
              />
              <button
                type="submit"
                form="form1"
                value="Submit"
                className="btnGo"
              >
                Go!
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="gap" /> */}
      <div
        onTouchStart={toggleHover}
        className={hover ? "flip-container hover" : "flip-container"}
      >
        <div className="flipper">
          <div className="front">
            <h2>Live Video Classes</h2>
            <img
              id="fimg3"
              className="flipper-image2"
              src={require("../assets/img/graphic_group_dance.png")}
              alt=""
            />
          </div>
          <div className="back">
            <h2>Live Video Classes</h2>
            <p>
              Coming Soon: Live video classes from top dance therapists and
              teachers who are passionate about Mind and Body medicine.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="gap" /> */}
    </div>
  );
};

export default WelcomeMenu;
