import React, { useEffect, useRef, useState } from "react";
import Peer from "peerjs";
import micOn from "../../assets/img/btn_mic_on.png";
import micOff from "../../assets/img/btn_mic_off.png";
import rotate from "../../assets/img/btn_camera_off.png";
import camOff from "../../assets/img/btn_video_off.png";
import camOn from "../../assets/img/btn_video_on.png";
import connectSound from "../../assets/audio/OnConnectionSound.wav";

const MediaContainer = (props) => {
  let localVideo = useRef();
  let remoteVideo = useRef();
  let [isAudioEnabled, setIsAudioEnabled] = useState(true);
  let [isVideoEnabled, setIsVideoEnabled] = useState(true);
  let [isCameraRotated, setIsCameraRotated] = useState(false);
  let [isPermissionGiven, setIsPermissionGiven] = useState(false);
  let [fname, setFname] = useState("");
  let [lname, setLname] = useState("");

  const [isOtherUserConnected, setIsOtherUserConnected] = useState(false);

  const peer = new Peer({
    host: process.env.REACT_APP_PEER_URL,
    // port: 9000,
    path: "/peerServer",
    secure: true,
  });

  useEffect(() => {
    let videoRef = null;
    toggleCameraRotation();

    console.log("GET USER MEDIA IN MEDIA CONTAINER");
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        localVideo.current.srcObject = stream;
        setIsPermissionGiven(true);
        if (localVideo.current) {
          videoRef = localVideo.current;
        }
      })
      .catch((e) => console.error("getUserMedia error: ", e.name));

      setTimeout(() => {
        const localVideo = document.querySelector(".local-video");
        const remoteVideo = document.querySelector(".remote-video");
    
        // Unmute the video on user interaction (click)
        document.addEventListener('click', function() {
          if (localVideo != null && remoteVideo != null) {
            if (localVideo.muted) {
              // localVideo.muted = false; // Unmute the video
              localVideo.play(); // Ensure the video keeps playing if paused
            }
            if (remoteVideo.muted) {
              remoteVideo.muted = false; // Unmute the video
              remoteVideo.play(); // Ensure the video keeps playing if paused
              console.log("REMOTE VIDEO UNMUTED");
            }
          }
        });     
      }, 2000); 

    return () => {
      if (videoRef) {
        videoRef.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    SetCameraRotationToMirrored();
    if (isPermissionGiven) {
      peer.on("call", (call) => {
        // console.log("peer on call: ", call);
        call.answer(localVideo.current.srcObject);
        call.on("stream", (videoStream) => {
          // console.log("peer on stream: ", videoStream);
          remoteVideo.current.srcObject = videoStream;
          setIsOtherUserConnected(true);
        });
      });

      props.socket.on("user-connect", function(peerId) {
        const audio = new Audio(connectSound);
        audio.play();
        // console.log("user-connect: ", peerId);
        let connection = peer.connect(peerId);
        connection.on("open", function() {
          const call = peer.call(peerId, localVideo.current.srcObject);
          call.on("stream", (videoStream) => {
            remoteVideo.current.srcObject = videoStream;
            setIsOtherUserConnected(true);
          });
        });
      });

      peer.on("open", (id) => {
        props.socket.emit("join-room", {
          roomName: props.roomName,
          peerId: id,
        });
        // console.log("MediaContainer peer on open id & props roomName: ", id, props.roomName, props.socket);
      });

      props.socket.on("user-disconnect", function() {
        remoteVideo.current.srcObject = null;
        setIsOtherUserConnected(false);
      });
    }
    return () => {
      peer.destroy();
    };
  }, [isPermissionGiven]);

  // Every Rerender
  useEffect(() => {
    if (props.fname != null) {
      setFname(props.fname);
      let last_name_obj = new String(props.lname);
      setLname(last_name_obj.substring(0,1) + '.');
    }
    
    // console.log(localVideo.current.srcObject);
    if (localVideo.current.srcObject != null) {
      // console.log(`(AUDIO, CAMERA) = (${props.isMicOn}, ${props.isCameraOn})`);
      if (props.isMicOn) {
        setAudioState(true)
      } else {
        setAudioState(false)
      }
      if (props.isCameraOn) {
        setVideoState(true)
      } else {
        setVideoState(false)
      }
    }
  });

  function toggleCameraRotation() {
    const video = document.getElementsByClassName("local-video");
    let factor = isCameraRotated ? "1" : "-1";
    video[0].style.transform = "scaleX(" + factor + ")";
    setIsCameraRotated(!isCameraRotated);
  }

  function SetCameraRotationToMirrored() {
    const video = document.getElementsByClassName("local-video");
    video[0].style.transform = "scaleX(-1)";
  }

  function toggleVideo() {
    localVideo.current.srcObject.getVideoTracks()[0].enabled = !isVideoEnabled;
    setIsVideoEnabled(!isVideoEnabled);
  }
  function setVideoState(state) {
    localVideo.current.srcObject.getVideoTracks()[0].enabled = state;
    setIsVideoEnabled(state);
  }

  function SetCameraRotationToMirrored() {
    const video = document.getElementsByClassName("local-video");
    video[0].style.transform = "scaleX(-1)";
  }

  function toggleAudio() {
    localVideo.current.srcObject.getAudioTracks()[0].enabled = !isAudioEnabled;
    setIsAudioEnabled(!isAudioEnabled);
  }

  function setAudioState(state) {
    localVideo.current.srcObject.getAudioTracks()[0].enabled = state;
    setIsAudioEnabled(state);
  }

  const flexHorizontal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  }
  const flexVertical = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  }

  const flexChild1 = {
    height: "100%",
    position: "relative",
  };

  const flexChild2 = {
    height: "100%",
    position: "relative",
  };

  return (
    <div id="camera-video-container" style={{display: "flex"}}>
      <div style={flexChild1}>
        <video
          style={{borderRadius: '10px', objectFit: 'cover'}}
          className="local-video"
          ref={localVideo}
          // Potential fix for IOS safari black video stream
          autoPlay
          muted
          playsInline
        />
        <div id="local-username" style={{position: 'absolute', bottom: 10, left: 10, backgroundColor: "#333", padding: '2px', borderRadius: '4px', opacity: .8}}>
          {fname} {lname}
        </div>
        {!props.isCameraOn ?
          <>
          <div style={{position: 'absolute', display:"flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', top: `${props.isFullScreen ? "50%" : "45%"}`, left: '50%', zIndex: 100, transform: 'translate3d(-50%, -50%, 0)', width: '65%'}}>
            
            <svg width="35%" height="35%" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.84401 5.25822L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C18.5298 22 19.8992 21.3129 20.8163 20.2306L15.5085 14.9227C14.8285 16.1608 13.5123 17 12 17C9.79086 17 8 15.2091 8 13C8 11.4877 8.83921 10.1715 10.0773 9.49154L5.84401 5.25822ZM15.9763 12.5621C15.7739 10.7028 14.2972 9.22615 12.4379 9.0237L7.32157 3.90736C7.14245 3.72824 7.12339 3.44094 7.30037 3.2597C8.06949 2.47205 9.13844 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V17C22 17.1894 21.9895 17.3763 21.969 17.5603C21.9268 17.9385 21.4745 18.0603 21.2053 17.7911L15.9763 12.5621ZM11.6215 11.0358C10.6979 11.2127 10 12.0248 10 13C10 14.1046 10.8954 15 12 15C12.9752 15 13.7873 14.3021 13.9642 13.3785L11.6215 11.0358Z"/>
            </svg>

            {props.isMicOn ?
              <><div style={{fontSize: '14px'}}>Your audio is on</div></> : <><div style={{fontSize: '14px'}}>You are muted</div></>
            }

          </div>
          </>
          :
          <>
            <div style={{position: 'absolute', top: '5%', left: '5%', zIndex: 100, color: '#fff', fontWeight: '700'}}>
              {props.isMicOn ?
                <>
                  <svg width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                    <path d="M8 5C8 2.79086 9.79086 1 12 1C14.2091 1 16 2.79086 16 5V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V5Z"/>
                    <path d="M6.25 11.8438V12C6.25 13.525 6.8558 14.9875 7.93414 16.0659C9.01247 17.1442 10.475 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0659 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V22C13.25 22.5523 12.8023 23 12.25 23H11.75C11.1977 23 10.75 22.5523 10.75 22V20.1548C9.02471 19.8903 7.41579 19.0831 6.16637 17.8336C4.61919 16.2865 3.75 14.188 3.75 12V11.8438C3.75 11.2915 4.19772 10.8438 4.75 10.8438H5.25C5.80228 10.8438 6.25 11.2915 6.25 11.8438Z"/>
                  </svg>
                </>
                :
                <>
                  <svg width="28px" height="28px" viewBox="0 0 24 24" fill="#CC3366" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1C13.6452 1 15.0585 1.99333 15.6728 3.41298L7.99997 11.0858V5C7.99997 2.79086 9.79083 1 12 1Z"/>
                    <path d="M6.24997 12C6.24997 12.2632 6.26801 12.5245 6.30342 12.7823L4.25194 14.8338C3.92295 13.9344 3.74997 12.9761 3.74997 12V11.8438C3.74997 11.2915 4.19769 10.8438 4.74997 10.8438H5.24997C5.80226 10.8438 6.24997 11.2915 6.24997 11.8438V12Z"/>
                    <path d="M7.3242 18.7971L3.76773 22.3535C3.3772 22.7441 2.74404 22.7441 2.35352 22.3535L1.64641 21.6464C1.25588 21.2559 1.25588 20.6227 1.64641 20.2322L20.2322 1.64644C20.6227 1.25591 21.2559 1.25591 21.6464 1.64644L22.3535 2.35354C22.744 2.74407 22.744 3.37723 22.3535 3.76776L16 10.1213V12C16 14.2091 14.2091 16 12 16C11.4457 16 10.9177 15.8873 10.4378 15.6835L9.13553 16.9857C9.99969 17.4822 10.986 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0658 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V23H10.75V20.1548C9.51944 19.9662 8.34812 19.5014 7.3242 18.7971Z"/>
                  </svg> 
                </>
              }
            </div>
          </>
        }
      </div>
      <div style={isOtherUserConnected ? flexChild2 : { display: "none" }}>
        <video
          style={{borderRadius: '10px', objectFit: 'cover'}}
          className="remote-video"
          ref={remoteVideo}
          // Potential fix for IOS safari black video stream
          autoPlay
          muted
          playsInline
        />
      </div>
    </div>
  );
};
export default MediaContainer;
