import React, { useRef, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import serializeForm from "form-serialize";
import * as usersAPI from "../utils/usersAPI";
import "../assets/css/userprofile.scoped.css";
import btn_home_Off3 from "../assets/img/btn_home_Off3.svg";
import btn_profile from "../assets/img/btn_profile.svg";
import btn_LogOut_Off3 from "../assets/img/btn_LogOut_Off3.svg";
import person_placeholder from "../assets/img/person-placeholder.png";
import Login from "./login/Login";
//import { Formik, Field, Form, ErrorMessage } from 'formik';

function UserProfile(props) {
  const [image, setImage] = React.useState(person_placeholder);
  // const [file, setFile] = React.useState(null);

  const [message, setMessage] = React.useState("");
  const [errormessage, setErrorMessage] = React.useState("");
  const [redirect, setRedirect] = React.useState(null);
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [username, SetUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [newsletter, setNewsletter] = React.useState(false);

  const [profile_type, setProfile_type] = React.useState("private");
  const [health_concern, setHealth_concern] = React.useState("");
  const [ethnicity, setEthnicity] = React.useState("");
  const [age, setAge] = React.useState("");
  const [fav_music, setFav_music] = React.useState("");
  const [fav_dance, setFav_dance] = React.useState("");
  const [hobbies_interest, setHobbies_interest] = React.useState("");
  const [personality_type, setPersonality_type] = React.useState("");

  useEffect(() => {
    // usersAPI.ensureAuth()
    // .then(user => {
    usersAPI.getUserData().then((data) => {
      setImage(data.user.image);
      SetUsername(data.user.username);
      setFname(data.user.fname);
      setLname(data.user.lname);
      setEmail(data.user.email);
      setNewsletter(data.user.newsletter);

      setProfile_type(data.user.profile_type || "private");
      setHealth_concern(data.user.health_concern);
      setEthnicity(data.user.ethnicity);
      setAge(data.user.age);
      setFav_music(data.user.fav_music);
      setFav_dance(data.user.fav_dance);
      setHobbies_interest(data.user.hobbies_interest);
      setPersonality_type(data.user.personality_type);
    });
    // })
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    const result = await usersAPI.updateUser({
      values,
    });
    //const handleAgeChange = (event) => {
    //const { value } = event.target;
    // Use regex to check if the input is an integer
    //const isIntegerInput = /^\d*$/.test(value);

    // If it's an integer or an empty string, update the state
    //if (isIntegerInput || value === '') {
    //  setAge(value);
    // }
    // };

    if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000); // Clears the error message after 5 seconds
    } else if (result.user) {
      setMessage(result.Message);
      SetUsername(result.user.username);
      setFname(result.user.fname);
      setLname(result.user.lname);
      setEmail(result.user.email);
      setNewsletter(result.user.newsletter);

      setProfile_type(result.user.profile_type);
      setHealth_concern(result.user.health_concern);
      setEthnicity(result.user.ethnicity);
      setAge(result.user.age);
      setFav_music(result.user.fav_music);
      setFav_dance(result.user.fav_dance);
      setHobbies_interest(result.user.hobbies_interest);
      setPersonality_type(result.user.personality_type);

      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
    return;
  };

  const updateImage = async (image) => {
    try {
      const { data } = await usersAPI.updateImage(image);
      return data;
    } catch (error) {}
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let file = null;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        //  setFile(file);
        setImage(result);
        updateImage({
          image: result,
          username: window.localStorage.getItem("username"),
        });
      })
      .catch((err) => {});

    //  setFile(e.target.files[0]);
  };

  const handleLogout = async (e) => {
    setRedirect("/");
    window.localStorage.removeItem("token");
    window.localStorage.setItem("authenticated", false);
  };

  // function getToken() {
  //     const tokenString = window.localStorage.getItem("token");
  //     return tokenString;
  // }
  // function getAuth() {
  //     const stat = window.localStorage.getItem("authenticated");
  //     return stat;
  // }

  if (redirect) {
    return (
      <Navigate
        replace
        to={{
          pathname: redirect,
        }}
      />
    );
  }

  //let tokenstatus = getToken();
  //let auth = getAuth();
  // if (tokenstatus == null || !auth) {
  //     return <Login />;
  // }
  const handleClick = (e) => {
    setProfile_type(e.target.value);
  };
  return (
    <div className="container mt-5">
      <div id="homeLogin">
        <Link to="/welcome">
          <img id="homeBtn" src={btn_home_Off3} alt="" />
        </Link>
        <Link to="/profile">
          <img id="profileBtn" src={btn_profile} alt="" />
        </Link>
        <form onSubmit={handleLogout}>
          <button type="submit" name="submit" id="submit">
            <img id="LogoutBtn" src={btn_LogOut_Off3} alt="" />
          </button>
        </form>
      </div>
      <div className="row" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <div className="col-lg-4 pb-5">
          {/* Account Sidebar*/}
          <div className="author-card pb-3">
            <div className="author-card-profile">
              <label htmlFor="photo-upload">
                <div className="author-card-avatar img-upload">
                  <img htmlFor="photo-upload" src={image} alt="user avatar" />
                  <input
                    id="photo-upload"
                    type="file"
                    name="file"
                    onChange={handleFileInputChange}
                  />
                </div>
              </label>
              <div className="author-card-details">
                <h5 className="author-card-name text-lg">
                  {fname} {lname}
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* Profile Settings*/}
        <div className="col-lg-8 pb-5 info-fields">
          <form className="row" onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="radio"
                  id="public"
                  name="profile_type"
                  value="public"
                  checked={profile_type === "public"}
                  onChange={handleClick}
                />
                <label htmlFor="public">Public</label>
              </div>
              <div className="form-group">
                <input
                  type="radio"
                  id="private"
                  name="profile_type"
                  value="private"
                  checked={profile_type === "private"}
                  onChange={handleClick}
                />
                <label htmlFor="private">Private</label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="health_concern">Health Concern</label>
                <input
                  className="form-control"
                  name="health_concern"
                  type="text"
                  id="health_concern"
                  defaultValue={health_concern}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="ethnicity">Ethnicity</label>
                <input
                  className="form-control"
                  name="ethnicity"
                  type="text"
                  id="ethnicity"
                  defaultValue={ethnicity}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="age">Age</label>
                <input
                  className="form-control"
                  name="age"
                  type="text"
                  id="age"
                  defaultValue={age}
                />
              </div>
            </div>
            {/* <ErrorMessage name="age">
                    {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage> */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fav_music">Favorite Music</label>
                <input
                  className="form-control"
                  name="fav_music"
                  type="text"
                  id="fav_music"
                  defaultValue={fav_music}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fav_dance">Favorite Dance</label>
                <input
                  className="form-control"
                  name="fav_dance"
                  type="text"
                  id="fav_dance"
                  defaultValue={fav_dance}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="hobbies_interest">Hobbies & Interest</label>
                <input
                  className="form-control"
                  name="hobbies_interest"
                  type="text"
                  id="hobbies_interest"
                  defaultValue={hobbies_interest}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="personality_type">Personality Type</label>
                <input
                  className="form-control"
                  name="personality_type"
                  type="text"
                  id="personality_type"
                  defaultValue={personality_type}
                />
              </div>
            </div>
            <div className="col-md-12" />
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-fn">First Name</label>
                <input
                  className="form-control"
                  name="fname"
                  type="text"
                  id="account-fn"
                  defaultValue={fname}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-ln">Last Name</label>
                <input
                  className="form-control"
                  name="lname"
                  type="text"
                  id="account-ln"
                  defaultValue={lname}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-email">E-mail Address</label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  id="account-email"
                  defaultValue={email}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-phone">User Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="account-username"
                  defaultValue={username}
                  disabled
                />
                <input name="username" type="hidden" defaultValue={username} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-pass">New Password</label>
                <input
                  className="form-control"
                  name="password"
                  type="password"
                  id="account-pass"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="account-confirm-pass">Confirm Password</label>
                <input
                  className="form-control"
                  name="password2"
                  type="password"
                  id="account-confirm-pass"
                />
              </div>
            </div>
            <div className="col-12">
              <hr className="mt-2 mb-3" />
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="custom-control custom-checkbox d-block">
                  <input
                    className="custom-control-input"
                    name="newsletter"
                    type="checkbox"
                    id="newsletter"
                    checked={newsletter}
                    onChange={() => setNewsletter(!newsletter)}
                  />
                  <label className="custom-control-label" htmlFor="newsletter">
                    Subscribe me to Newsletter
                  </label>
                </div>
                <button
                  className="btn btn-style-1 btn-primary"
                  type="submit"
                  name="submit"
                  data-toast
                  data-toast-position="topRight"
                  data-toast-type="success"
                  data-toast-icon="fe-icon-check-circle"
                  data-toast-title="Success!"
                  data-toast-message="Your profile updated successfuly."
                >
                  Update Profile
                </button>
                <span className="status-message">{message}</span>
                <span className="error-message">{errormessage}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
