import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import NavBar from "../NavBar";
import NotVerified from "./NotVerified";
import { Link, Navigate } from "react-router-dom";
import login_facebook from "../../assets/img/btn_login_facebook.png";
import login_twitter from "../../assets/img/btn_login_twitter.png";
import login_google from "../../assets/img/btn_login_google.png";
import login_linkedin from "../../assets/img/btn_login_linkedin.png";
import * as usersAPI from "../../utils/usersAPI";
import serializeForm from "form-serialize";
import Welcome from "../Welcome";
import "../../assets/css/welcome.scoped.css";
import "../../assets/css/bootstrap.scoped.css";
import { Nav } from "react-bootstrap";
import { apiUrl, readCookie } from "../../utils/utils";
import { getActivatedEmailMessage } from "../../utils/messages";
import ResentVerification from "./ResentVerification";
const api = apiUrl();

const Login = () => {
  const [smallMessage, setSmallMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [notUserVerified, setNotUserVerified] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const { userId } = useParams();

  const confirmUser = () => {
    // Do we need this?
  };

  const sendWelcome = () => {
    // Do we need this?
  };

  const handleVerify = async () => {
    const result = await usersAPI.confirmUser(userId);
    // TODO: verify result and handle errors
    if (result.email) {
      usersAPI.sendWelcome(result.email);
      setSmallMessage(getActivatedEmailMessage(result.email));
    };
  };

  useEffect(() => {
    if (userId) handleVerify();

  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      username: username.toLowerCase(),
      password: password,
    };
    const result = await usersAPI.loginUser(body);
    const response = await result.json();
    // localStorage.setItem('fname', response.user.fname);

    if (result.ok) {
      setLoggedIn(true);
    } else if (result.status === 403) {
      //setMessage(getNotVerifiedMessage(body.username));
      setNotUserVerified(true);
    } else {
      setSmallMessage(response.errorMessage);
    }
  };
  if (loggedIn) {
    return <Navigate to="/welcome" />;
  } else if (resendSuccess) {
    return (
      <>
        <NavBar />
        <ResentVerification username={username} />
      </>
    );
  } else if (notUserVerified) {
    return (
      <>
        <NavBar />
        <NotVerified
          handleResendSuccess={() => setResendSuccess(true)}
          username={username}
        />
      </>
    );
  } else {
    return (
      <>
        <NavBar />
        <div
          style={{ marginTop: 75, textAlign: "center", }}
          className="container login-container"
        >
          <h1>Log In</h1>
          <p
           style={{
            textAlign: "center",
            fontSize: 20,
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 300,
            paddingBottom: 5,
          }}
          >Log in with your social account</p>
          <div className="icons">
            <a href={`${api}/users/facebook`}>
              <img src={login_facebook} alt="" />
            </a>
              {/* <a href="/">
                <img src={login_twitter} alt="" />
              </a> */}
            <a href={`${api}/users/google`}>
              <img src={login_google} alt="" />
            </a>
            <a href={`${api}/users/linkedin`}>
              <img src={login_linkedin} alt="" />
            </a>
          </div>
          {smallMessage ? (
            <p
              style={{
                border: "2px solid #e7f036",
                borderRadius: "6px",
                marginTop: 20,
                marginBottom: 20,
                padding: 15,
                fontFamily: '"Roboto", sans-serif',
                fontSize: 18,
                fontWeight: 300,
                letterSpacing: 1,
              }}
            >{smallMessage}
            </p>
          ) : null}
          <form onSubmit={handleSubmit} autoComplete="on" style={{marginTop: '20px'}}>
            <div className="form-group form-container">
              <label
                style={{
                  float: "left",
                  fontFamily: '"Roboto", sans-serif',
                }}
                className="form-label"
              >
                Username
              </label>
              <input
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                className="form-control container"
              />
            </div>
            <div style={{ marginBottom: 2 }} className="form-group form-container">
              <label
                style={{
                  float: "left",
                  fontFamily: '"Roboto", sans-serif',
                }}
                className="form-label"
              >
                Password
              </label>
              <input
                name="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control container"
              />
            </div>
            <div
              style={{
                marginTop: 20,
                paddingBottom: 12,
                fontSize: 14,
                fontFamily: '"Roboto", sans-serif'
              }}
            >
              <Link to="/passwordreset" style={{ marginTop: "-10px" }}>
                Forgot your password?
              </Link>
            </div>
            {/* harder font, like open sans or montserrat*/}
            <button
              type="submit"
              name="submit"
              style={{marginTop: 36}}
              // style={{ marginTop: 36, marginLeft: "-120px" }}
              className="btn-lg register2"
            >
              Let's Dance!
            </button>
            <p
              style={{
                textAlign: "center",
                paddingTop: 18,
                fontSize: 20,
                paddingBottom: 18,
                borderTopStyle: "dotted",
                borderWidth: 2,
                marginTop: 24,
                fontFamily: '"Roboto", sans-serif',
                fontWeight: 300,
                letterSpacing: 1,
              }}
            >
              No account and want to dance?
            </p>
            <a
              href="/register"
              role="button"
              className="btn btn-lg login2"
            >
              Join the Party
            </a>
          </form>
        </div>
      </>
    );
  }
};
export default Login;
